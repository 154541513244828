export const pbCup = {
  id: "milkshakePbCup",
  backgroundColor: "#C65100",
  textColor: "#FFFFFF",
  name:
    "Moules au Beurre d’Arachides fait avec Reese® Moules au Beurre d’Arachides®",
  tagline: "C'est l'heure du milk-shake au beurre d'arachide",
  description: false,
  ingredients:
    "Substances laitières modifiées, Eau, Sucres (sucre, glucose), Crème, Reese® Moules au Beurre d'Arachides (sucre, beurre de cacao, liqueur de chocolat, substances laitières, lécithine de soja, polyricinoléate de polyglycerol, arachides, dextrose, sel, TBHQ, acide citrique), Brisures de chocolat au lait (sucre, liqueur de chocolat, substances laitières, beurre de cacao, lécithine de soja, arôme naturel), Beurre d'arachide (arachides, huile d'arachide, sucre, sel, fécule de maïs), Gomme de caroube, Gomme de guar, Carraghénine.",
  disclaimer:
    "Contient: Lait, Arachides, Soja. Peut contenir: Noix, Blé, Œufs provenant de la fabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "450",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "20g",
        percent: "27%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "11g<br>0.3g",
        percent: "55%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "46g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "54g",
        percent: "54%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "7g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "60mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "300mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "600mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "200mg",
        percent: "15%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "0.5mg",
        percent: "3%",
      },
    ],
  },
}

export const cookiesCream = {
  id: "milkshakeCookiesCream",
  backgroundColor: "#3B1E00",
  textColor: "#FFFFFF",
  name: "Biscuits et Crème",
  tagline: "L'équipe de rêve ultime",
  description: false,
  ingredients:
    "Substances laitières modifiées, Eau, Sucres (sucre, glucose), Crème, Biscuits au chocolat fourrés à la crème (farine enrichie, sucre, huiles de palme et de palmiste, cacao, farine de maïs, sel, bicarbonate de sodium, dextrose, lécithine de soja, arômes naturels), Arômes naturels et artificiels de vanille, Gomme de caroube, Gomme de guar, Carraghénine.",
  disclaimer:
    "Contient: Lait, Blé, Soja. Peut contenir: Arachides, Noix, Œufs provenant de la fabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "420",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "15g",
        percent: "20%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "9g<br>0.3g",
        percent: "45%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "47g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "51g",
        percent: "51%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "55mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "250mg",
        percent: "11%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "650mg",
        percent: "14%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "175mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "1mg",
        percent: "6%",
      },
    ],
  },
}

export const chocolateChill = {
  id: "milkshakeChocolateChill",
  backgroundColor: "#78452B",
  textColor: "#FFFFFF",
  name: "Chocolat",
  tagline: "Chocoholics Unite",
  description: false,
  ingredients:
    "Substances laitières modifiées, Sucres (sucre, glucose), Eau, Crème, Cacao, Gomme de caroube, Gomme de guar, Carraghénine.",
  disclaimer:
    "Contient: Lait. Peut contenir : Arachides, Noix, Soja, Blé, Œufs provenant de la fabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "450",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "16g",
        percent: "21%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "10g<br>0.5g",
        percent: "55%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "74g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "2g",
        percent: "7%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "61g",
        percent: "61%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "60mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "280mg",
        percent: "12%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "40mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "175mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "3mg",
        percent: "17%",
      },
    ],
  },
}

export const vanillaBliss = {
  id: "milkshakeVanillaBliss",
  backgroundColor: "#F0D08B",
  textColor: "#004E7D",
  name: "Vanille",
  tagline: "Vanille",
  description: false,
  ingredients:
    "Substances laitières modifiées, Eau, Sucres (sucre, glucose), Crème, Arômes naturels et artificiels de vanille, Gomme de caroube, Gomme de guar, Carraghénine.",
  disclaimer:
    "Contient: Lait.  Peut contenir: Arachides, Noix, Soja, Blé, Œufs provenant de la fabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "410",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "15g",
        percent: "20%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "9g<br>0.4g",
        percent: "45%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "43g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "53g",
        percent: "53%",
      },
      {
        type: "primary",
        label: "Protéins",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "65mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "290mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "650mg",
        percent: "14%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "200mg",
        percent: "15%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "0.1mg",
        percent: "1%",
      },
    ],
  },
}

export const coolMintChip = {
  id: "milkshakeCoolMintChip",
  backgroundColor: "#75E1CA",
  textColor: "#004E7D",
  name: "Brisures et Menthe",
  tagline: "Brisures et Menthe",
  description: false,
  ingredients:
    "Substances laitières modifiées, Eau, Sucres (sucre, glucose), Crème, Brisures de chocolat (sucre, chocolat amer, beurre de cacao, extrait de vanille naturel), Gomme de caroube, Gomme de guar, Carraghénine, Arôme naturel, Extrait de curcuma, Bleu brillant.",
  disclaimer:
    "Contient: Lait.  Peut contenir: Arachides, Noix, Soja, Blé, Œufs provenant de la fabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "440",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "17g",
        percent: "23%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "11g<br>0.3g",
        percent: "55%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "48g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "55g",
        percent: "55%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "60mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "270mg",
        percent: "12%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "650mg",
        percent: "14%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "175mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "0.75mg",
        percent: "4%",
      },
    ],
  },
}

export const cottonCandy = {
  id: "milkshakeCottonCandy",
  backgroundColor: "#51BDF5",
  textColor: "#004E7D",
  name: "Barbe à Papa",
  tagline: "Barbe à Papa",
  description: false,
  ingredients:
    "Substances laitières modifiées, Eau, Sucres (sucre, glucose), Crème, Fécule de maïs modifiée, Gomme de caroube, Gomme de guar, Acide citrique, Arômes naturels et artificiels, Dioxyde de titane, Bleu brilliant, Sorbate de potassium, Carraghénine.",
  disclaimer:
    "Contient: Lait.  Peut contenir: Arachides, Noix, Soja, Blé, Œufs provenant de la fabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "430",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "14g",
        percent: "19%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "9g<br>0.4g",
        percent: "45%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "50g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "57g",
        percent: "57%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "60mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "280mg",
        percent: "12%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "650mg",
        percent: "14%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "175mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "0.1mg",
        percent: "1%",
      },
    ],
  },
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { TypeTitle, TypeDescription, CardRow } from "./Card"
import Flavor from "./Flavor"

import {
  pbCup,
  cookiesCream,
  chocolateChill,
  vanillaBliss,
  coolMintChip,
  cottonCandy,
} from "../../data/milkshakeDataFr"

export default function FrenchMilkshakes(props) {
  const { activeFlavor, setActiveFlavor, incomingFlavor } = props

  const images = useStaticQuery(graphql`
    query flavorsMilkshakesFr {
      pbCupFr: file(
        relativePath: { eq: "front-page-flavors/milkshake-pb-cup-can.png" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      cookiesCreamFr: file(
        relativePath: {
          eq: "front-page-flavors/milkshake-cookies-cream-can.png"
        }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      chocolateChillFr: file(
        relativePath: { eq: "front-page-flavors/milkshake-chocolate-can.png" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      vanillaBlissFr: file(
        relativePath: { eq: "front-page-flavors/milkshake-vanilla-can.png" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      coolMintChipFr: file(
        relativePath: { eq: "front-page-flavors/milkshake-mint-chip-can.png" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      cottonCandyFr: file(
        relativePath: {
          eq: "front-page-flavors/milkshake-cotton-candy-can.png"
        }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <TypeTitle>Milkshakes</TypeTitle>
      <TypeDescription>
        Laissez-vous enthousiasmer par nos délicieuses saveurs de base faites
        avec du vrai lait. De plus, nos saveurs en édition limitée sortent
        chaque année!
      </TypeDescription>

      <CardRow>
        <Flavor
          data={cookiesCream}
          fixed={images.cookiesCreamFr.childImageSharp.fixed}
          flavorIndex={1}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={vanillaBliss}
          fixed={images.vanillaBlissFr.childImageSharp.fixed}
          flavorIndex={2}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={chocolateChill}
          fixed={images.chocolateChillFr.childImageSharp.fixed}
          flavorIndex={3}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />
      </CardRow>

      <CardRow>
        <Flavor
          data={cottonCandy}
          fixed={images.cottonCandyFr.childImageSharp.fixed}
          flavorIndex={4}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={coolMintChip}
          fixed={images.coolMintChipFr.childImageSharp.fixed}
          flavorIndex={5}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={pbCup}
          fixed={images.pbCupFr.childImageSharp.fixed}
          flavorIndex={6}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />
      </CardRow>
    </>
  )
}
